import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
const instance = axios.create({
    withCredentials: true,
    // headers:{
    //     "Access-Control-Allow-Origin": "*",
    //     'Content-Type':'application/json'
    // }
  })
  
const getAxios=async (url,data)=>{
    let res;
    try{
        res=await instance.get(url,data)
        return res
    }catch(err){
        whowErrorText(err)
      return err
    }
}
const postAxios=async (url,data)=>{
    try{
        const res=await instance.post(url,data)
        return res
    }catch(err){
        whowErrorText(err)
        return err
    }
}
const whowErrorText=(err)=>{
    console.log("err",err)
    toast(err.message)
}
const putAxios=async (url,data)=>{
    try{
        const res=await instance.put(url,data)
        return res
    }catch(err){
        whowErrorText(err)
        
    }
}
const deleteAxios=async (url,data)=>{
    try{
        const res=await instance.delete(url,data)
        return res
    }catch(err){
        whowErrorText(err)
    }
}


export default getAxios;
export {postAxios,putAxios,deleteAxios}