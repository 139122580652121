// const BASE_URL="http://learn2code.redgrape.tech:8000/"
const BASE_URL="https://api.loyaltyapp.sugufabrics.com/"
const BASE_URL2="https://api2.loyaltyapp.sugufabrics.com/"
const BASE_URL3="http://suviappdash.easyerp.biz/"
const request={
    login_user:BASE_URL+"api/v1/admin/verify_user",
    verify_user:BASE_URL2+"account/verify_user",
    who_ami:BASE_URL+"api/v1/admin/whoami",
    who_ami2:BASE_URL2+"account/whoami",
    log_out:BASE_URL+"api/v1/admin/loggoff",
    qr_list:BASE_URL2+"labels/list_qrcodes",
    generate_qr:BASE_URL+"api/v1/admin/generate_qrcodes",
    // generate_qr:BASE_URL2+"labels/generate_qr_code",
    exportQrCode:BASE_URL+"api/v1/admin/export_qrcodes",
    productList:BASE_URL+"api/v1/admin/list_products",
    // batchList:BASE_URL+"api/v1/admin/list_batch",
    batchList:BASE_URL2+"labels/list_batch",
    addPosts:BASE_URL2+"posts/add_post",
    categoryMaster:BASE_URL2+"yancy/api/CategoryMaster",
    gallery:BASE_URL2+"posts/list_post",
    postGallery:BASE_URL2+"posts/add_post",
    newExportQrCode:BASE_URL2+"labels/export_qrcodes",
    listCategory:BASE_URL2+"posts/list_category",
    deleteCategory:BASE_URL2+"yancy/api/Post",
    updateExpiry:BASE_URL2+"yancy/api/BatchMaster",
    productIdList:BASE_URL2+"product/product_list",
    listUser:BASE_URL2+"account/list_users",
    pushnotification:BASE_URL2+"account/send_pushnotification"
   
}
export default request