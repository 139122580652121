import axios from 'axios'
import React, { Component, Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import request from './components/request/request'
import { useNavigate } from 'react-router-dom'
import './scss/style.scss'
import getAxios from './components/axios/Axios'
import { Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import "./App.scss"
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const [loginStatus, setLoginStatus] = useState(true)
  const loginCheck=async ()=>{
    
    const res=await getAxios(request.who_ami)
    const res1=await getAxios(request.who_ami2)
    if(res?.data?.apiresponse?.type=="OK" && res1?.data?.apiresponse?.type=="OK"){
      setLoginStatus(true)
    }else{
      setLoginStatus(false)
    }
  }
  useEffect(()=>{
    loginCheck()
  },[])

  return (
    <HashRouter>
      <Suspense fallback={loading}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />
        <Routes>
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          
          {loginStatus && <Route path="*" name="Home" fallback={loading} element={<DefaultLayout setLoginStatus={setLoginStatus}/>} />}
          {!loginStatus && <Route path="*" name="Login Page" fallback={loading} element={<Login setLoginStatus={setLoginStatus}/>} />}
         {/* <Route exact path="/login" name="Login Page" element={<Login />} />

          <Route path="*" name="Home" element={<DefaultLayout />} /> */}
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
